.ui.styled.accordion .title {
  cursor: pointer;
  font-size: 1.2em;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f7f7f7;
  margin: 0.5em 0;
}

.ui.styled.accordion .title:hover {
  background-color: #ebebeb;
}

.ui.styled.accordion .title.active {
  background-color: #e0e0e0;
}

.ui.styled.accordion .content {
  display: none;
  padding: 1em;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 0.5em;
}

.ui.styled.accordion .content.active {
  display: block;
}

.ui.styled.accordion .content p {
  margin: 0;
}
